import React, { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'src/store';
import { Redirect, useLocation } from 'react-router-dom';
import { runtimeConfigs } from 'src/config/runtimeConfigs';
import { useSettings } from '@cigam/template/dist/hooks/useSettings';
import { setHistoryNavigation } from 'src/Areas/Genericos/GE/slices/historyNavigation';

interface Props {
  children: ReactNode;
}

export const AuthRoute: FC<Props> = ({ children }) => {
  const { settings } = useSettings();
  const location = useLocation();
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);

  useEffect(() => {
    if (!account.user) {
      const path = location.pathname;

      dispatch(setHistoryNavigation(path));
    }
  }, [account.user, dispatch, location.pathname]);

  if (!account.user) {
    let { defaultLogin } = settings;

    if (!defaultLogin) {
      const searchEnviroment = async () => {
        const enviroment = await runtimeConfigs();
        defaultLogin = enviroment.defaultLogin || 'cigam';
      };

      searchEnviroment();
    }

    if (typeof defaultLogin === 'string') {
      switch (defaultLogin) {
        case 'contato':
          return <Redirect to="/logincontato" />;
        default:
          return <Redirect to="/login" />;
      }
    }
  }

  if (account.type !== 'cigam' && account.type !== 'contato') {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};
