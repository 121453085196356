export default [
  21, // Unidade de negócio padrão
  339, // Utilizar configurador de produto
  397, // Tipo operação saída consumidos
  398, // Tipo operação entrada fabricados
  399, // Tipo operação entrada defeito
  392, // Centro armazenagem produção
  393, // Centro armazenagem fabricados
  394, // Centro armazenagem defeito
  395, // Centro armazenagem perdidos
  400, // Tipo operação entrada perdidos
  402, // Leitura por
  403, // Area de Processo
  404, // Confirmar quantidades
  426, // Ocorrência de entrada fabricados
  427, // Ocorrência de entrada defeito
  428, // Ocorrência de entrada perdidos
  535, // Suprimir registros sem saldo na pesquisa de Estoque
  596, // Separar por lotes na disponibilidade
  671, // Suprimir Registros Zerados na Pesquisa de Lotes
  772, // Permite alterar os parametros
  901, // TG
  1196, // Bloqueio de registros dos Acompanhamentos
  1545, // Impressão de etiqueta disponível
  2016, // Divisão dos colaboradores padrão
  2549, // Ativar controle de substituição de demandas na produção
  2563, // Permitir acesso ao Cronograma do Projeto
  2608, // Permitir acessar projetos como PMO
  3096, // Exigir informar colaborador ao ler ordem de produção
  3119, // Ao clicar no ícone da leitura da OP no portal, abrir:
];
