import { FC } from 'react';
import {
  Trello as TrelloIcon,
  PieChart as PieChartIcon,
  IconProps,
} from 'react-feather';
import { DashBoardRelatorios as DashBoardRelatoriosIcon } from '@cigam/template/dist/components/Icons/DashBoardRelatorios';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SearchIcon from '@mui/icons-material/Search';

import { MODULE } from 'src/config';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface Item {
  title: string;
  href: string;
  icon?: FC<IconProps> | OverridableComponent<SvgIconTypeMap>;
  direito?: string;
  invisible?: boolean;
  items?: Item[];
}

export interface INavConfig {
  moduleName: string;
  nav: {
    subheader: string;
    items: Item[];
  }[];
}

export const navConfig: INavConfig[] = [
  {
    moduleName: '/portalservicos',
    nav: [
      {
        subheader: 'Gerenciamento de Equipe',
        items: [
          {
            title: 'Kanban',
            href: `${MODULE.portalservicos.location}/Kanban`,
            icon: TrelloIcon,
          },
        ],
      },
    ],
  },
  {
    moduleName: '/portalindustria',
    nav: [
      {
        subheader: 'INDÚSTRIA',
        items: [
          {
            title: 'Dashboard',
            icon: PieChartIcon,
            href: `${MODULE.portalindustria.location}/Dashboard`,
          },
          {
            title: 'Leitor de produção',
            icon: CenterFocusStrongIcon,
            href: `${MODULE.portalindustria.location}/LeitorProducao`,
          },
          {
            title: 'Movimentos de produção',
            icon: SyncAltIcon,
            href: `${MODULE.portalindustria.location}/MovimentosProducao`,
          },
          {
            title: 'Pesquisa Ordens de Produção',
            icon: SearchIcon,
            href: `${MODULE.portalindustria.location}/PDOrdensProducao`,
            direito: 'MNPCPESMOV',
          },
          // Adicionar menu para tela de Impressão (Modelo Relatório). OS 558743/149
          /* {
            title: 'Imprimir Empresa',
            icon: SearchIcon,
            href: `${MODULE.portalindustria.location}/ImprimirEmpresa`,
          }, */
          {
            title: 'Relatórios',
            icon: DashBoardRelatoriosIcon,
            href: '/',
            items: [
              {
                title: 'Emissão da OP',
                href: `${MODULE.portalindustria.location}/Relatorios/CG06464/Emissão da OP/PCIMOP`,
              },
              {
                title: 'Emissão de Talão',
                href: `${MODULE.portalindustria.location}/Relatorios/CG06465/Emissão de Talão/PCIMTA`,
              },
              {
                title: 'Previsto X Realizado por OP',
                href: `${MODULE.portalindustria.location}/Relatorios/CG06178/Previsto X Realizado por OP/6178`,
              },
              {
                title: 'Previsto x Realizado por Material',
                href: `${MODULE.portalindustria.location}/Relatorios/CG06172/Previsto X Realizado por Material/6172`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    moduleName: '/portalcliente',
    nav: [
      {
        subheader: 'ÁREA FINANCEIRA',
        items: [
          {
            title: 'Boletos',
            icon: ReceiptIcon,
            href: `${MODULE.portalcliente.location}/Boletos`,
          },
        ],
      },
    ],
  },
];
