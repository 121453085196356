import { ScrollReset } from '@cigam/template/dist/components';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import React, { useCallback, useEffect, useState, FC } from 'react';
import Helmet from 'react-helmet';
import { Router } from 'react-router-dom';
import { runtimeConfigs } from 'src/config/runtimeConfigs';
import { useSettings } from '@cigam/template/dist/hooks/useSettings';
import Routes from 'src/Routes';
import { isAuthenticated } from 'src/Areas/Genericos/GE/services/authService';
import { loadAllConfiguracoesSistema } from 'src/Areas/Genericos/GE/services/configuracoesSistemaService';
import { createTheme } from '@cigam/template/dist/theme';
import axios from 'src/utils/axios';
import axiosDebug from 'src/utils/axiosDebug';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from './components/Auth';

const history = createBrowserHistory();

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  })
);

const App: FC = () => {
  const { settings, saveSettings } = useSettings();
  const [render, setRender] = useState(false);

  useStyles();

  const searchEnviroment = useCallback(
    async () => {
      const enviroment = await runtimeConfigs();

      if (enviroment) {
        saveSettings({
          ...settings,
          apiUrl: enviroment.API_URL || null,
          cdnUrl: enviroment.CDN_URL || null,
          cdnKey: enviroment.CDN_KEY || 1,
          apmMonitorUrl: enviroment.APM_MONITOR_URL || null,
          themes: enviroment.THEMES || null,
          theme:
            settings.theme !== 'CIGAM'
              ? settings.theme
              : enviroment.DEFAULT_THEME,
          portalBpmUrl: enviroment.PORTAL_SERVICOS_URL,
          activePortals: [...enviroment.ACTIVE_PORTALS] || null,
          defaultLogin: enviroment.DEFAULT_LOGIN || null,
          pdUrl: enviroment.PD_URL || null,
        });

        if (process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
          axios.defaults.baseURL = `${enviroment.API_URL}/api`;
          axiosDebug.defaults.baseURL = `${enviroment.API_URL}/apm`;
        }
      }

      return enviroment;
    },
    [settings, saveSettings] // OK
  );

  useEffect(() => {
    const load = async () => {
      const enviroment = await searchEnviroment();
      if (enviroment?.API_URL && isAuthenticated()) {
        loadAllConfiguracoesSistema();
      }
      setRender(true);
    };
    if (!render) {
      load();
    }
  }, [render, searchEnviroment]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'pt-BR' }} />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createTheme(settings)}>
          {/* @ts-ignore */}
          <SnackbarProvider maxSnack={10} autoHideDuration={3000}>
            <Router history={history}>
              <Auth>
                <ScrollReset />
                <Routes />
              </Auth>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
