import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import {
  loginWithCodeVerification,
  loginWithUserNameAndPassword,
  logout as logoutService,
} from '../services/authService';
import { loadAllDireitos } from '../services/direitosService';
import { UserDTO } from '../types/dtos/auth';

export interface AccountState {
  user: UserDTO | null;
  direitos: string[];
  type: 'cigam' | 'contato';
}

const initialState: AccountState = {
  user: null,
  direitos: [],
  type: 'cigam',
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearAccount(state: AccountState) {
      state.user = null;
      state.direitos = [];
    },
    setAccount(state: AccountState, action: PayloadAction<AccountState>) {
      const { user, type } = action.payload;

      state.user = user;
      state.type = type || 'cigam';
    },
  },
});

export const { reducer } = slice;

export const { setAccount } = slice.actions;

export const login =
  (userName: string, password: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.clearAccount());

    const user = await loginWithUserNameAndPassword(userName, password);

    const direitos = await loadAllDireitos();

    dispatch(
      slice.actions.setAccount({ user: user[0], type: 'cigam', direitos })
    );
  };

export const loginContato =
  (
    portal: string,
    codePerson: string,
    sequence: number,
    codeVerification: string
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.clearAccount());

    const user = await loginWithCodeVerification(
      portal,
      codePerson,
      sequence,
      codeVerification
    );

    const direitos = await loadAllDireitos();

    dispatch(
      slice.actions.setAccount({ user: user[0], type: 'contato', direitos })
    );
  };

export const logout =
  (): AppThunk =>
  (dispatch): void => {
    logoutService();

    dispatch(slice.actions.clearAccount());
  };
