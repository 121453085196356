import { SplashScreen } from '@cigam/template/dist/components';
import { useSnackbar } from 'notistack';
import qs from 'qs';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'src/store';
import { useLocation } from 'react-router-dom';
import { runtimeConfigs } from 'src/config/runtimeConfigs';
import {
  handleAuthentication,
  isAuthenticated,
  loginInWithToken,
  setAxiosInterceptors,
  logout as logoutService,
} from 'src/Areas/Genericos/GE/services/authService';
import axios from 'src/utils/axios';
import { logout, setAccount } from 'src/Areas/Genericos/GE/slices/account';
import { loadAllDireitos } from 'src/Areas/Genericos/GE/services/direitosService';

interface Props {
  children: ReactNode;
}

export function Auth({ children }: Props) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { hash } = qs.parse(useLocation().search, { ignoreQueryPrefix: true });

  useEffect(() => {
    const initAuth = async () => {
      setAxiosInterceptors(() => dispatch(logout()));

      const enviroment = await runtimeConfigs();

      if (enviroment) {
        axios.defaults.baseURL = `${enviroment.API_URL}/api`;
      }

      await handleAuthentication(String(hash));

      if (isAuthenticated()) {
        try {
          const user = await loginInWithToken();
          const type = user.tipoLogin;
          const direitos = await loadAllDireitos();

          dispatch(setAccount({ user, type, direitos }));
        } catch (error) {
          enqueueSnackbar(String(error), { variant: 'error' });
          logoutService();
        }
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch, enqueueSnackbar, hash]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return <>{children}</>;
}
